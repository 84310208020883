import Rails from '@rails/ujs';

$(document).on("turbolinks:load", function() {

  const $form = $("#new_student");

  const $input = $("#student_username");

  function enableForm() {
    Rails.enableElement($form[0]);
    $form.removeAttr("disabled");
  }

  let selectizeCallback = null;

  $input.on('input',function(e){
    $input.removeClass("is-invalid");
  });

  $("#add-student-modal").on("hide.bs.modal", function (e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selectizeCallback = null;
    }

    // enableForm();
    $("#new_student").trigger("reset");
  });

  $form.on("submit", function (e) {
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      success: function (response) {
        console.log("response from creating student,", response);
        $input.addClass("is-valid");
        selectizeCallback({value: response.id, text: response.username});
        selectizeCallback = null;

        $("#add-student-modal").modal('toggle');
      },
      error: function (response) {
        const errors = response.errors;
        console.error("Error adding student,", errors);
        $("#student_username").addClass("is-invalid");
        $("#modal-error").css('visibility','visible')
        // alert("That's not a valid username. Try another.");
        // enableForm();
      }
    });
  });

  $("#selectize-students").selectize({
    plugins: ['remove_button', 'restore_on_backspace'],
    delimiter: ',',
    persist: false,
    create: function (input, callback) {
      selectizeCallback = callback;

      $("#add-student-modal").modal();
      $("#student_username").val(input);
      // TODO: make RETURN submit the form, or auto-highlight the submit button
    }
  });
});
