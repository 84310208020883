// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>


// inspired by https://onrails.blog/2018/03/13/stimulus-js-tutorial-how-do-i-update-my-model-from-a-checkbox/

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "subscribed", "output" ]

  connect() {
    // console.log("Hello from the receieve_newsletter controller!");
    // console.log(`newsletter box is checked? ${this.subscribedTarget.checked}`);
  }

  toggle(event) {
    function getMetaValue(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`)
      return element.getAttribute("content")
    }
    // let formData = new FormData;
    // formData.append(toggle_newsletter[subscribed], this.subscribedTarget.checked);
    // console.log("receive_newsletter_controller got checkbox toggle event");
    const _this = this;
    fetch(this.data.get("update-url"), {
      // body: formData,
      method: 'PATCH',
      dataType: 'script',
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function(response) {
      if (response.status != 204) {
        event.target.checked = !event.target.checked
        _this.toggleCheckbox()
        _this.showSubbed(event.target.checked);
      }
    })
  }

  toggleCheckbox() {
    this.subscribedTarget.checked = !this.subscribedTarget.checked
  }

  showSubbed(subbed) {
    if (subbed) {
      this.outputTarget.textContent = "✅ You are subscribed!";
    } else {
      this.outputTarget.textContent = "🚫 You aren't subscribed.";
    }
  }
}
