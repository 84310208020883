// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// * = require jquery3
// * = require popper
// * = require bootstrap

require("@rails/ujs").start()
//* = require rails-ujs
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("bootstrap/dist/js/bootstrap")
// this is a hack https://stackoverflow.com/questions/58371711/bootstrap-hide-modal-not-working-in-rails-6-using-ajax
import jquery from 'jquery';
window.$ = window.jquery = jquery;
require("bootstrap")
// import 'bootstrap/dist/js/bootstrap'
// import 'bootstrap/dist/css/bootstrap'
require("jquery")
require("selectize")
require("packs/selectize-students")
require("packs/sort-table")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
